import api from '../utils/api'

export default {
  get (orgId) {
    return api.get(`collaborgs/${orgId}`)
  },
  update (orgId, org) {
    return api.patch(`collaborgs/${orgId}`, org)
  },
  getAll (params) {
    return api.get('collaborgs', { params: params })
  }
}
