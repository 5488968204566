<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">Организации</h4>

                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="control has-addons">
                                    <input v-model.trim="query.search" type="text" placeholder="Поиск"
                                           class="input" @keyup.enter="search">
                                    <a class="button is-info" :class="{ 'is-loading': loading }"
                                       @click.prevent="search">Найти</a>
                                </p>
                            </div>
                        </div>
                    </nav>

                    <div class="notification is-primary" v-if="query.search && !haveItems()">
                        Ничего не найдено
                    </div>

                    <spinner v-show="loading"></spinner>

                    <table class="table is-striped" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>Название</th>
                            <th>ИНН</th>
                            <th>Количство сотрудников</th>
                            <th>Реквизиты</th>
                            <!--<th></th>-->
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(org, index) in items">
                            <td>{{ org.title }}</td>
                            <td>{{ org.inn }}</td>
                            <td>{{ org.collabs.length }}</td>
                            <td>
                                <ol>
                                   <li v-if="org.bik">БИК: {{ org.bik }}</li>
                                   <li v-if="org.address">Адрес: {{ org.address }}</li>
                                   <li v-if="org.checking_account">Р/с: {{ org.checking_account }}</li>
                                   <li v-if="org.correspond_account">Кор/с: {{ org.correspond_account }}</li>
                                   <li v-if="org.kpp">КПП: {{ org.kpp }}</li>
                                </ol>
                            </td>
                            <!--<td class="is-icon">-->
                                <!--<router-link :to="{ name: 'CollabOrg', params: { id: org.uuid }}">-->
                                    <!--<i class="fa fa-edit"></i>-->
                                <!--</router-link>-->
                            <!--</td>-->
                        </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import collabOrgService from '../../services/collaborg'
import itemListViewMixin from '../../mixins/itemListView'

export default {
  mixins: [itemListViewMixin(collabOrgService, 'org', true)],
  methods: {
  }
}
</script>
